<template>
    <div class="orders-list-body">
        <template v-for="item of items" :key="item">
            <ProductDetailsTile
                v-if="item.is_pie"
                :pie="item"
                type="post_bake"
                :orderID="item?.orderID"
                :itemsTotal="item?.totalPies"
                :customerName="item?._customerName"
                :warningColor="item?._warningColor"
                :orderNumberRaw="item?.orderNumberRaw"
                :pickupTime="item?._pickupDisplay"
                :startTime="item?._startTimeDisplay"
            />
            <ProductDetailsTile
                v-if="item.is_extra"
                :extras="item.items"
                type="post_bake"
                :orderID="item?.orderID"
                :itemsTotal="item?.totalPies"
                :customerName="item?._customerName"
                :warningColor="item?._warningColor"
                :orderNumberRaw="item?.orderNumberRaw"
                :pickupTime="item?._pickupDisplay"
                :startTime="item?._startTimeDisplay"
            />
        </template>
        <NoOrders v-if="items?.length == 0"/>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { orders } from '@/services/firebase';
import ProductDetailsTile from '@/components/ProductDetailsTile/ProductDetailsTile.vue';
import NoOrders from '@/components/NoOrders/NoOrders.vue';

export default defineComponent({
    components: { ProductDetailsTile, NoOrders },
    setup:() => ({ items: computed(()=>orders.postBaked) }),
});
</script>
