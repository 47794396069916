
import { computed, defineComponent } from "vue";
import { orders } from '@/services/firebase';
import ProductDetailsTile from '@/components/ProductDetailsTile/ProductDetailsTile.vue';
import NoOrders from '@/components/NoOrders/NoOrders.vue';

export default defineComponent({
    components: { ProductDetailsTile, NoOrders },
    setup:() => ({ items: computed(()=>orders.postBaked) }),
});
